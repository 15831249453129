import axios from 'axios';
import { useState } from 'react'
import countryList from './Countries.js';
import { useNavigate } from 'react-router-dom';

const Form = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    bemail: '',
    company: '',
    job: '',
    country: '',
    phone: '',    
    requirements: '',
    type: "customizedReport"
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://marketdatalabs.com/submit-form', formData);
      console.log(response.data);
      setFormData({
        fname: '',
        lname: '',
        bemail: '',
        company: '',
        job: '',
        country: '',
        phone: '',    
        requirements: '',
        type: "customizedReport"
      });
      navigate('/thankyou');
      // Handle success (e.g., show a success message, clear the form, etc.)
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
      <div className="form-container">
        <div className="font-semibold site-color" style={{fontSize: '30px', paddingBottom: "10px"}}>Looking for a customized report for your market?</div>
        <div className="font-medium-italic" style={{fontSize: '12px', paddingBottom: "10px"}}>Share your contact deets, and we'll get back to you quicker than a shooting star!</div>
        <form onSubmit={handleSubmit}>
          <div style={{display: "flex", flexDirection: "row"}}>
              <div className="form__group field font-regular" style={{flex: "1"}}>
                  <input type="input" class="form__field" placeholder="fname" name="fname" id='fname' value={formData.fname} onChange={handleChange} required />
                  <label for="fname" class="form__label">First Name *</label>
              </div>
              <div className="form__group field font-regular" style={{flex: "1"}}>
                  <input type="input" class="form__field" placeholder="lname" name="lname" id='lname' value={formData.lname} onChange={handleChange} required />
                  <label for="lname" class="form__label">Last Name *</label>
              </div>
          </div>
          <div className="form__group field font-regular" style={{width: "98%"}}>
              <input type="input" class="form__field" placeholder="bemail" name="bemail" id='bemail' value={formData.bemail} onChange={handleChange} required />
              <label for="bemail" class="form__label">Business Email *</label>
              <div className="font-regular" style={{fontSize: '12px', paddingTop: "10px", color: "#9b9b9b"}}>Please fill in Business Email for Quick Response</div>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
              <div className="form__group field font-regular" style={{flex: "1"}}>
                  <input type="input" class="form__field" placeholder="company" name="company" id='company' value={formData.company} onChange={handleChange} required />
                  <label for="company" class="form__label">Company *</label>
              </div>
              <div className="form__group field font-regular" style={{flex: "1"}}>
                  <input type="input" class="form__field" placeholder="job" name="job" id='job' value={formData.job} onChange={handleChange} required />
                  <label for="job" class="form__label">Job Title / Designation *</label>
              </div>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
              <div className="form__group field font-regular" style={{flex: "1"}}>
                  <select className="form__select" id="country" name="country" value={formData.country} onChange={handleChange} required>
                      <option value="" disabled>Select a country</option>
                      {countryList.map((country, index) => (
                          <option key={index} value={country}>{country}</option>
                      ))}
                  </select>
              </div>
              <div className="form__group field font-regular" style={{flex: "1"}}>
                  <input type="input" class="form__field" placeholder="phone" name="phone" id='phone' value={formData.phone} onChange={handleChange} required />
                  <label for="phone" class="form__label">Phone no *</label>
              </div>
          </div>
          <div className="form__group field font-regular" style={{width: "98%"}}>
              <textarea className="form__field" placeholder="requirements" name="requirements" id='requirements' value={formData.requirements} onChange={handleChange} required/>
              <label for="requirements" class="form__label">Requirements *</label>
              <div className="font-regular" style={{fontSize: '12px', paddingTop: "10px", color: "#9b9b9b"}}>Share your specific area of interest for our Analysts to help you better</div>
          </div>
          <div style={{paddingTop: "30px"}}><button  type="submit" className='button'>Send</button></div>
        </form>
      </div>

  );
};

export default Form;
