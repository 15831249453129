import { useParams, useNavigate } from 'react-router-dom';
import data from "../reportData.json"
import countryList from './Countries.js';
import axios from 'axios';
import { useState } from 'react'


const RequestReport = () => {
    const { id } = useParams();
    const item = data.find(d => d.id === id);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        bemail: '',
        company: '',
        job: '',
        country: '',
        phone: '',    
        requirements: '',
        type: "syndicateReport",
        action: '',
        reportCode: item.id
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('https://marketdatalabs.com/submit-form', formData);
          console.log(response.data);
          navigate('/thankyou');
            //Handle success
        } catch (error) {
          console.error('There was an error submitting the form!', error);
          // Handle error (e.g., show an error message)
        }
      };
    
    return (
        <section style={{backgroundColor: "#64e986", height: "750px", paddingTop: "50px"}}>
            <div className="footer-form">
                <div className="testimony-container">
                    <div className="font-semibold site-color testimony-card">Report Deets</div>
                    <div className="font-regular" style={{padding: "0 20px 20px 20px"}}>
                        <p><span className="font-medium-italic" style={{fontSize: "1.1rem", color: "#64e986"}}>Title: </span>{item.title}</p>
                        <p><span className="font-medium-italic" style={{fontSize: "1.1rem", color: "#64e986"}}>Category: </span>{item.category}</p>
                        <p><span className="font-medium-italic" style={{fontSize: "1.1rem", color: "#64e986"}}>Code: </span>{item.id}</p>
                        <p><span className="font-medium-italic" style={{fontSize: "1.1rem", color: "#64e986"}}>Pages: </span>{item.pages}</p>
                        <p><span className="font-medium-italic" style={{fontSize: "1.1rem", color: "#64e986"}}>Price: </span>$ {item.price}</p>
                    </div>
                </div>

                <div className="form-container">
                    <div className="font-semibold site-color" style={{fontSize: '30px', paddingBottom: "10px"}}>Looks like you found your Report !!</div>
                    <div className="font-medium-italic" style={{fontSize: '12px', paddingBottom: "10px"}}>Share your contact deets, and we'll get back to you quicker than a shooting star!</div>
                    <form onSubmit={handleSubmit}>    
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className="form__group field font-regular" style={{flex: "1"}}>
                                <input type="input" class="form__field" placeholder="fname" name="fname" id='fname2' value={formData.fname} onChange={handleChange} required />
                                <label for="fname2" class="form__label">First Name *</label>
                            </div>
                            <div className="form__group field font-regular" style={{flex: "1"}}>
                                <input type="input" class="form__field" placeholder="lname" name="lname" id='lname2' value={formData.lname} onChange={handleChange} required />
                                <label for="lname2" class="form__label">Last Name *</label>
                            </div>
                        </div>
                        <div className="form__group field font-regular" style={{width: "98%"}}>
                            <input type="input" class="form__field" placeholder="bemail" name="bemail" id='bemail2' value={formData.bemail} onChange={handleChange} required />
                            <label for="bemail2" class="form__label">Business Email *</label>
                            <div className="font-regular" style={{fontSize: '12px', paddingTop: "10px", color: "#9b9b9b"}}>Please fill in Business Email for Quick Response</div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className="form__group field font-regular" style={{flex: "1"}}>
                                <input type="input" class="form__field" placeholder="company" name="company" id='company2' value={formData.company} onChange={handleChange} required />
                                <label for="company2" class="form__label">Company *</label>
                            </div>
                            <div className="form__group field font-regular" style={{flex: "1"}}>
                                <input type="input" class="form__field" placeholder="job" name="job" id='job2' value={formData.job} onChange={handleChange} required />
                                <label for="job2" class="form__label">Job Title / Designation *</label>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className="form__group field font-regular" style={{flex: "1"}}>
                                <select className="form__select" name="country" id="country2" value={formData.country} onChange={handleChange} required>
                                    <option value="" disabled>Select a country</option>
                                    {countryList.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form__group field font-regular" style={{flex: "1"}}>
                                <input type="input" class="form__field" placeholder="phone" name="phone" id='phone2' value={formData.phone} onChange={handleChange} required />
                                <label for="phone2" class="form__label">Phone no *</label>
                            </div>
                        </div>
                        <div className="form__group field font-regular" style={{width: "98%"}}>
                            <textarea className="form__field" placeholder="requirements" name="requirements" id='requirements2' value={formData.requirements} onChange={handleChange} required/>
                            <label for="requirements2" class="form__label">Requirements *</label>
                            <div className="font-regular" style={{fontSize: '12px', paddingTop: "10px", color: "#9b9b9b"}}>Share your specific area of interest for our Analysts to help you better</div>
                        </div>
                        <div className="form__group field font-regular" style={{ width: '98%' }}>
                            <div className="radio-group">
                                <label className="radio-label" style={{flex: "1"}}>
                                    <input type="radio" name="action" value="buyReport" onChange={handleChange} required/>
                                    <span>Buy Report</span>
                                </label>
                                <label className="radio-label" style={{flex: "3"}}>
                                    <input type="radio" name="action" value="needSample" onChange={handleChange} required/>
                                    <span>Request free Sample instead</span>
                                </label>
                            </div>
                            <div style={{fontSize: "12px", color: "#9b9b9b", paddingTop: "10px"}}>Our team will contact you to assist with payment methods, as direct payments via our website are currently unavailable.</div>
                        </div>
                        <div style={{paddingTop: "30px"}}><button className='button'>Send</button></div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default RequestReport;