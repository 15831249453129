import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTractor, faTruck, faFlask, faLightbulb, faUtensils, faComputer, faHeartPulse, faMicrochip, faPersonDigging, faPumpSoap, faPlane, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom';

const Categories = () => {
  return (
    <section className="category-section">
        <div className="font-semibold site-color" style={{fontSize: '45px', paddingBottom: '25px'}}>Market Categories</div>
        <div className="categories-container font-medium site-color" style={{fontSize: '18px', letterSpacing: '0.05em'}}>
            <div className="row">
                <div className="category">
                    <NavLink to={`/reports/AGRI`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faTractor} size="3x" />
                        <span className="category-name">Agriculture</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/AUTO`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faTruck} size="3x" />
                        <span className="category-name">Automotive</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/CHEM`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faFlask} size="3x" />
                        <span className="category-name">Chemicals and Materials</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/ENER`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faLightbulb} size="3x" />
                        <span className="category-name">Energy and Power</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/FOOD`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faUtensils} size="3x" />
                        <span className="category-name">Food and Beverage</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/IACT`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faComputer} size="3x" />
                        <span className="category-name">Information Technology (ICT)</span>
                    </NavLink>
                </div>
            </div>
            <div className="row">
                <div className="category">
                    <NavLink to={`/reports/HEAL`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faHeartPulse} size="3x" />
                        <span className="category-name">Life Sciences and Healthcare</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/ELEC`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faMicrochip} size="3x" />
                        <span className="category-name">Semiconductors and Electronics</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/CONS`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faPersonDigging} size="3x" />
                        <span className="category-name">Mining and Construction</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/GOOD`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faPumpSoap} size="3x" />
                        <span className="category-name">Consumer Goods</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/DEFE`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faPlane} size="3x" />
                        <span className="category-name">Aerospace and Defence</span>
                    </NavLink>
                </div>
                <div className="category">
                    <NavLink to={`/reports/BFSI`} className="category-link">
                        <FontAwesomeIcon className="category-icon" icon={faBuildingColumns} size="3x" />
                        <span className="category-name">Financial Services & Insurance (BFSI)</span>
                    </NavLink>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Categories;
