import logo from '../assets/green/logo2.png';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="navbar site-color">
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="links font-medium">
                <NavLink to="/">Home</NavLink>
                <NavLink to="/categories">Market Categories</NavLink>
                <NavLink to="/reports/AGRI">Reports</NavLink>
                <NavLink to="/about">About Us</NavLink>
                <NavLink to="/contact">Contact Us</NavLink>
            </div>
        </nav>
    );
};

export default Navbar;
