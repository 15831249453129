import ImageIntro from "./ImageIntro";
import Categories from "./Categories";
import LatestReport from "./LatestReport";

const Home = () => {
    return (
        <div>
            <ImageIntro />
            <Categories />
            <LatestReport />
        </div>    
    )
};

export default Home;