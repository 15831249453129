import ThankYouImage from '../assets/green/ThankYou.jpg';
import Categories from "./Categories";
import LatestReport from "./LatestReport";
const ThankYou = () => {
    return (
        <section>
            <div className="intro-section">
                <div className="image-section" style={{ backgroundImage: `url(${ThankYouImage})`, flex: '1' }}></div>
                <div className="text-section" style={{ flex: '1' }}>
                    <div className="introduction">
                        <div className="font-semibold site-color" style={{fontSize: '50px'}}>Thank you for sharing your requirements with us.</div>
                        <h3 className="font-medium-italic">We are pleased to have the opportunity to collaborate with you and are eager to address your needs. Our team will reach out to you at the earliest convenience.</h3>
                        <div className="font-regular">In the meantime, you can explore more reports or market categories below.</div>
                    </div>
                </div>
            </div>
            <Categories />
            <LatestReport />
        </section>
    )
};

export default ThankYou;