import Form from "./Form.js"
import Testimony from "./Testimony.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <section className="footer-section">
            <div className="footer-form">
                <Form />
                <Testimony />
            </div>
            <div className="footer-content">
                <div className="contact">
                    <div className="font-semibold site-color testimony-card">Contact Us</div>
                    <div style={{display: "flex", padding: "5px 0 15px 20px", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faEnvelope} size="xl" className="site-color" style={{paddingRight: "10px"}}/>
                        <div className="font-regular" style={{fontSize: "19px"}}>marketdatalabs@gmail.com</div>
                    </div>
                    <div style={{display: "flex", padding: "5px 0 15px 20px", alignItems: "center"}}>
                        <FontAwesomeIcon icon={faPhone} size="xl" className="site-color" style={{paddingRight: "10px"}}/>
                        <div className="font-regular" style={{fontSize: "19px"}}>+91 1234512345</div>
                    </div>
                </div>
                <div className="footer-policy">
                    <div className="font-semibold site-color">
                        <div><span style={{padding: "0 10px", color: "black"}}>|</span>
                        Privacy Policy
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        Terms & Conditions
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        Disclaimer
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        About Us
                        <span style={{padding: "0 10px", color: "black"}}>|</span></div>
                        <div>All Reports
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        Format & Delivery
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        Pricing
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        Payment Options</div>
                        <div><span style={{padding: "0 10px", color: "black"}}>|</span>
                        Refund Policy
                        <span style={{padding: "0 10px", color: "black"}}>|</span>
                        Careers
                        <span style={{padding: "0 10px", color: "black"}}>|</span></div>
                    </div>
                </div>
            </div>
            <div className="font-semibold" style={{color: "white", textAlign: "center"}}>COPYRIGHT © 2024 ALL RIGHTS RESERVED</div>
        </section>
    );
};
export default Footer;