import { NavLink } from 'react-router-dom';

const ReportBlock = (props) => {
    // Access the passed data object
    const data = props.item;

    // Access individual fields from the data object
    const id = data.id;
    const title = data.title;
    const category = data.category;
    const pages = data.pages;
    const price = data.price;   


    return (
        <section className="report-block font-regular">
            <div className="report-description">
                <div style={{fontSize: '20px', paddingBottom: '10px'}}>{title}</div>
                <div className="report-tags">
                    <div><span className="site-color" style={{fontWeight: '800'}}>Code:</span> {id}<span className="site-color" style={{fontWeight: '800', paddingLeft: '15px'}}>Category:</span> {category}<span className="site-color" style={{fontWeight: '800', paddingLeft: '15px'}}>Pages:</span> {pages}</div>
                    <div className="request-link">
                        <NavLink to={`/requestreport/${id}`} className="site-color">
                            Request Sample
                        </NavLink>
                    </div>    
                </div>
            </div>
            <div className="report-pricing">
                <div style={{fontSize: '20px', paddingBottom: '10px'}}>$ {price}</div>
                <div>
                    <NavLink to={`/requestreport/${id}`}>
                        <button className="button font-regular">Buy Report</button>
                    </NavLink>
                </div>
            </div>
        </section>
    );
};
export default ReportBlock;