import AboutImage from '../assets/green/AboutUs.jpg';
import Services from '../assets/green/Services.jpg';
const About = () => {
    return (
        <section>
            <div className="intro-section">
                <div className="image-section" style={{ backgroundImage: `url(${AboutImage})`, flex: '1' }}></div>
                <div className="text-section" style={{ flex: '1' , paddingTop: '50px'}}>
                    <div className="font-semibold site-color" style={{fontSize: '45px', paddingBottom: '25px'}}>Overview</div>
                    <div className="font-regular">
                        <p>Market Data Labs is a global competitor in data analytics, offering essential tools to address questions and understand the complex interplay of internal and external factors influencing market dynamics. We deliver innovative, detailed, and comprehensive insights through market research, tailored to meet your individual and organizational goals. Our expertise helps you make well-informed, strategic decisions, enabling you to capitalize on emerging trends, developments, forecasts, and opportunities with a precise understanding of the market.</p>
                        <p>Our reports serve as foundational elements for the sustained growth of businesses by presenting innovative outcomes and solutions to effectively manage various industry challenges and limitations. The reliability of our data is ensured by pertinent supporting information, facilitating a methodical approach and dependable validation through expert skills and verified methodologies. We empower our clients to evaluate and anticipate their business needs, leading to insightful decision-making that significantly benefits them. In doing so, we provide value to our clients by enhancing their customer management processes in ways that support the organization and its stakeholders.</p>
                    </div>
                </div>
            </div>
            <div className="intro-section">
                <div className="text-section" style={{ flex: '1' , paddingTop: '50px'}}>
                    <div className="font-semibold site-color" style={{fontSize: '45px', paddingBottom: '25px', paddingLeft: '80px'}}>Our Services</div>
                    <div className="font-regular" style={{paddingLeft: '80px'}}>
                        <ul>
                            <li style={{marginBottom: '10px'}}>Syndicated Research</li>
                            {/* <li>Subject Matter Expertise Zone</li> */}
                            <li style={{marginBottom: '10px'}}>Bespoke Proposals</li>
                            <li style={{marginBottom: '10px'}}>Market Size Estimations</li>
                            <li style={{marginBottom: '10px'}}>Companny & Market Best Practices</li>
                            <li style={{marginBottom: '10px'}}>Entry or Go-To-Market Strategies</li>
                            <li style={{marginBottom: '10px'}}>Market Dynamics, Positioning and Segmentations</li>
                            <li style={{marginBottom: '10px'}}>Opportunity Analysis</li>
                            <li style={{marginBottom: '10px'}}>Economic Forecasting</li>
                            <li style={{marginBottom: '10px'}}>Industry-Specific Technology Solutions</li>
                            <li style={{marginBottom: '10px'}}>In-depth benchmarking of vendor offerings</li>
                            <li style={{marginBottom: '10px'}}>Primary Interviews</li>
                        </ul>
                    </div>
                </div>
                <div className="image-section" style={{ backgroundImage: `url(${Services})`, flex: '1' }}></div>
            </div>
        </section>
    );
};

export default About;