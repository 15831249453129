import Footer from "./Footer";
import {Routes , Route } from "react-router-dom"
import Home from "./Home";
import About from "./About";
import Navbar from './Navbar';
import Categories from "./Categories";
import Reports from "./Reports";
import Contact from "./Contact";
import ScrollToTop from "./ScrollToTop";
import RequestReport from "./RequestReport";
import ThankYou from "./ThankYou";

const Main = () => {
    return (
        <div>
            <Navbar />
            <ScrollToTop />
            <Routes> 
                <Route path="/" element={<Home/> } /> 
                <Route path="/categories" element={<Categories/> } />
                <Route path="/reports/:param" element={<Reports/> } />
                <Route path="/about" element={<About/> } />
                <Route path="/contact" element={<Contact/> } />
                <Route path="/requestreport/:id" element={<RequestReport/> } />
                <Route path="/thankyou" element={<ThankYou/> } />
            </Routes>
            <Footer />
        </div>    
    )
};
export default Main;