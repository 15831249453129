import Introduction from './Introduction';
import LandingImage from '../assets/green/DataExtraction.jpg';
import LandingImage1 from '../assets/green/ReviewReport.jpg';
import LandingImage2 from '../assets/green/Analysis.jpg';

const ImageIntro = () => {
    return (
        <section>
            
            <div className="intro-section">
                <div className="text-section" style={{ flex: '1' }}>
                    <Introduction />
                </div>
                <div className="image-section" style={{ backgroundImage: `url(${LandingImage})`, flex: '2' }}></div>
            </div>
            <div className="intro-section">
                <div className="image-section" style={{ backgroundImage: `url(${LandingImage1})`, flex: '5' }}></div>
                <div className="text-section" style={{ flex: '3', padding: '35px 0px' }}>
                    <div className="font-semibold site-color" style={{fontSize: '45px', paddingBottom: '25px'}}>Market Analysis</div>
                    <div className="font-regular">At Market Data Labs, we specialize in delivering comprehensive market reports to our clients, providing detailed insights into various industries and market forecasts. Through meticulous data analysis and thorough research, we offer valuable information on market trends, competitor analysis, consumer behavior, and future projections. Our goal is to equip our clients with the knowledge they need to make informed decisions and stay ahead in today's dynamic business landscape. With Market Data Labs, businesses can confidently navigate market complexities, anticipate future trends, and seize new opportunities for growth.</div>
                </div>
                <div className="image-section" style={{ backgroundImage: `url(${LandingImage2})`, flex: '4' }}></div>
            </div>
        </section>
    );
};

export default ImageIntro;