import ReportBlock from './ReportBlock.js'
import data from "../reportData.json"
import { NavLink } from 'react-router-dom';

const LatestReport = () => {
    const fiveReports = data.slice(0,5);

    return (
        <section>
            <div className="category-section font-semibold site-color" style={{fontSize: '45px', paddingBottom: '25px'}}>Latest Reports</div> 
            {
                fiveReports.map( item => (
                        <ReportBlock key={item.id} item={item}/>
                    )
                )
            }
            <div style={{textAlign: 'center', marginBottom: '20px'}}>
                <NavLink to="/reports/AGRI">
                    <button className="button">View all Reports</button>
                </NavLink>
            </div>
        </section>
    )
};
export default LatestReport;