import Client from "../assets/clients/maleClient1.png";

const Testimony = () => {
  return (
    <div className="testimony-container">
      <div className="font-semibold site-color testimony-card">Our Clients Say...</div>
      <div className="font-regular" style={{padding: "0 20px 20px 20px"}}>
        <p>"Working with Market Data Labs is hassle free and convenient. It helped me grow my business, understand my competition and improve my product/service offering."</p>
        <div className="client-info">
          <img src={Client} alt="Client" />
          <p>Name</p>
        </div>
      </div>
      <div className="font-regular" style={{padding: "20px"}}>
        <p>"Working with Market Data Labs is hassle free and convenient. It helped me grow my business, understand my competition and improve my product/service offering."</p>
        <div className="client-info">
          <img src={Client} alt="Client" />
          <p>Name</p>
        </div>
      </div>
    </div>
  );
};

export default Testimony;
