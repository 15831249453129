import ReportBlock from './ReportBlock.js'
import data from "../reportData.json"
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTractor, faTruck, faFlask, faLightbulb, faUtensils, faComputer, faHeartPulse, faMicrochip, faPersonDigging, faPumpSoap, faPlane, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


const Reports = () => {
    const { param } = useParams();
    const [selectedCategory, setSelectedCategory] = useState("AGRI");
    const [filteredReports, setFilteredReports] = useState([]);
    const [isAnimated, setIsAnimated] = useState(false);

    const categoryMap = {
        AGRI: {title: "Agriculture", description: "Agriculture industry related lines", icon: faTractor},
        AUTO: {title: "Automotive", description: "Automotive industry related lines", icon: faTruck},
        CHEM: {title: "Chemicals and Materials", description: "Chemicals and Materials industry related lines", icon: faFlask},
        ENER: {title: "Energy and Power", description: "Energy and Power industry related lines", icon: faLightbulb},
        FOOD: {title: "Food and Beverage", description: "Food and Beverage industry related lines", icon: faUtensils},
        IACT: {title: "Information & Communication Technology (ICT)", description: "Information & Communication Technology (ICT) industry related lines", icon: faComputer},
        HEAL: {title: "Life Sciences and Healthcare", description: "Life Sciences and Healthcare industry related lines", icon: faHeartPulse},
        ELEC: {title: "Semiconductors and Electronics", description: "Semiconductors and Electronics industry related lines", icon: faMicrochip},
        CONS: {title: "Mining and Construction", description: "Mining and Construction industry related lines", icon: faPersonDigging},
        GOOD: {title: "Consumer Goods", description: "Consumer Goods industry related lines", icon: faPumpSoap},
        DEFE: {title: "Aerospace and Defence", description: "Aerospace and Defence industry related lines", icon: faPlane},
        BFSI: {title: "Banking, Financial Services & Insurance (BFSI)", description: "Banking, Financial Services & Insurance (BFSI) industry related lines", icon: faBuildingColumns},
    }

    useEffect(() => {
        const filtered = data.filter(item => item.id.slice(0, 4) === selectedCategory);
        setFilteredReports(filtered);
      }, [selectedCategory]);

    useEffect(() => {
        setIsAnimated(true); // Start animation on category change
        const timer = setTimeout(() => {
          setIsAnimated(false); // Stop animation after 1 second (or the duration of your animation)
        }, 1000); // Duration of the animation
        return () => clearTimeout(timer); // Clean up timer
      }, [selectedCategory]);

    useEffect(() => {
        if (param) {
          setSelectedCategory(param);
        } else {
          setSelectedCategory("AGRI");
        }
      }, [param]);

    return (
        <section>
            
            
            <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                    <div className="report-category-box">
                        <div className="font-semibold site-color" style={{fontSize: '25px', padding: '20px 0 20px 0', textAlign: 'center', borderBottom: '1px solid rgba(0, 0, 0, 0.2)'}}>Categories</div>                
                        <div className="font-regular" style={{padding: '0 5px 10px 0'}}>
                            <ul>
                                {Object.keys(categoryMap).map((code) => (
                                    <li key={code} style={{ marginBottom: '10px', cursor: 'pointer'}} className={selectedCategory === code ? 'font-semibold site-color' : ''} onClick={() => setSelectedCategory(code)}>
                                        <NavLink to={`/reports/${code}`} className="category-link">
                                            {categoryMap[code].title}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{flex: '5'}}>
                    <div className="banner">
                        <div style={{flex: '1', textAlign: 'center', paddingRight: '15px'}}>
                            <FontAwesomeIcon icon={categoryMap[selectedCategory].icon} size="5x" className={isAnimated ? 'animate-once' : ''} />
                        </div>
                        <div style={{flex: '9', paddingLeft: '15px'}}>
                            <div className="font-semibold" style={{fontSize: '30px', paddingBottom: "10px"}}>{categoryMap[selectedCategory].title}</div>
                            <div className="font-regular">Banner - {categoryMap[selectedCategory].description}</div>
                        </div>
                    </div>
                    {
                        filteredReports.map(item => (
                            <ReportBlock key={item.id} item={item} />
                        ))
                    }
                </div>    
            </div>
        </section>
    )
};

export default Reports;